import React, { useState, useEffect } from "react";
import "./App.css";
import "./components/Cards.css";


// * Components
import { CardBox, Card, ImageCard } from "./components/Cards.js";


// * Projects Link
function App() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fonction pour charger les projets depuis le fichier JSON
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/projects/projects.json`);
        const data = await response.json();
        //console.log(data);

        const project_cards = [];

        //const project_cards = data.map(project => (projectCard(project)));
        Object.entries(data).forEach(([id, card], key) => {
          project_cards.push(
            <Card
              title={card.name}
              labels={[card.category]}
              color="#33F"
              special={card.year}
              cover={`${process.env.PUBLIC_URL}/projects/${id}/index.jpg`}
            >
              {card.description}
            </Card>
          )});

        setProjects(project_cards);
      } catch (error) {
        console.error("Erreur lors du chargement des projets :", error);
      }
    };

    // Appel de la fonction pour charger les projets
    fetchProjects();
  }, []);

  return (
    <div className="App">
      <CardBox title="William Mercier | Portfolio">
        {projects}
      </CardBox>
    </div>
  );
}

export default App;
