import React, { useState, useEffect } from "react";
import "./Cards.css";


// * Components ----------------------------------------------------------------


/** CardBox - Contains Cards */
export const CardBox = ({ title, children }) => {
  return (
    <div>
      <div className="header">{title}</div>
      <div className="card-box">
        {children}
      </div>
    </div>
  )
}


/** EmptyLabel - Contains label */
export const Label = ({ children, color="#000", style }) => {
  const colorStyle = { background: color };
  return <div className="label" style={{...colorStyle, ...style}}>{children}</div>
}


/** Card - Contains content with padding */
export const Card = ({ children, title, labels, special, color="#CCC", style, cover }) => {
  const [header, setHeader] = useState(null);

  const HTML_cover = () => {if (cover) {console.log(cover); return(
    <img className="card-cover" src={cover} alt="cover"/>
  )}}


  useEffect(() => {
    const HTML_title = () => {if (title) {return(
      <h1>{title}</h1>
    )}}

    const HTML_special = () => {if (special) {return(
      <Label style={{color: color, background: "transparent"}}>
        {special}
      </Label>
    )}}

    const HTML_labels = () => {if (labels) {return(
      <div className="card-header-labels">{
        labels.map(label => (
          <Label color={typeof(label) === typeof([]) ? label[1] : color}>
            {typeof(label) === "string" ? label : label[0]}
          </Label>
        ))
      }</div>
    )}}

    if (title || labels || special) {
      setHeader(
      <div className="card-header">
        <div className="card-header-title">{HTML_title()}{HTML_special()}</div>
        {HTML_labels()}
      </div>
      );
    }

  }, [children, title, labels, special, cover, color]);

  return (
    <div className="card-container" style={style}>
      {HTML_cover()}
      <div className="card-body">
        {header}
        {children}
        </div>
    </div>
  )
}


export const ImageCard = ({ children, src }) => {
  return <Card style={{padding:0}}>{children}</Card>
}